
::v-deep td.text-align-left {
    text-align: left !important;
}
.catalogs-tit {
    height: calc(1.35em * 2);
    line-height: 1.35;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
